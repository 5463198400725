import React, { Component } from "react";

class ContactThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rnName: "",
      rnEmail: "",
      rnSubject: "",
      rnMessage: "",
    };
  }
  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">{this.props.contactTitle}</h2>
                <p className="description">
                  I am available. Connect with me via WhatsApp:{" "}
                  <a href="https://wa.me/966543630584">+966 54 363 0584</a> or email:
                  <a href="mailto:dev.yousef99@gmail.com"> dev.yousef99@gmail.com</a>{" "}
                </p>
              </div>

            </div>

          </div>
        </div>
      </div>
    );
  }
}
export default ContactThree;
