import React ,{ Component }from "react";
import { FiBox , FiLayers , FiArchive  , FiMonitor, FiLayout, FiLink, FiUsers } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiLayout />,
        title: 'Web Development',
        description: 'For web Development i build some apps using reactJS and Angular, also html and css'
    },
    {
        icon: <FiLayers />,
        title: 'Database',
        description: 'build a suitable DB using PHPmyAdmin eaither MongoDB based on the project.'
    },
    {
        icon: <FiArchive  />,
        title: 'Reporting',
        description: 'Creating a web page for generate report using SQL Commands on Back-end Side.'
    },
    { 
        icon: <FiBox />,
        title: 'Mobile App Development',
        description: 'Develop and Enhance mobile apps build with flutter or React-native.'
    },
    { 
        icon: <FiUsers />,
        title: 'SharePoint',
        description: 'Support Exist SharePoint and Generate access for users and creating folders.'
    },
    { 
        icon: <FiMonitor />,
        title: 'Power Bi',
        description: 'Analyis data and build dashboards.'
    },
    {
        icon: <FiLink />,
        title: 'Back-End',
        description: "Generates a REST api's using php and nodeJS based on project."
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
