import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class TabsTwo extends Component {
  render() {
    let tab1 = "Experience",
      tab2 = "Skills",
      // tab3 = "Technical Language",
      tab4 = "Education & Certification";
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    {/* <Tab>{tab3}</Tab> */}
                    <Tab>{tab4}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                      <li>
                          2024 Fep - Present<br></br>
                          <b>IT Analyst </b>- Adbullatif jameel Motors
                        </li>
                      <li>
                          2022 Sep - 2024 Feb<br></br>
                          <b>Application Developer </b>- NAPCO National Co.
                        </li>
                        <li>
                          2021 Nov - 2022 Sep<br></br>
                          <b>Application Development Team Leader </b>- Attar Software Engineering
                        </li>
                        <li>
                          2021 Jun - 2021 Nov<br></br>
                          <b>Technical Support Specialist </b>- Maghrabi Medical Group
                        </li>
                      <li>
                          2020 Mar - 2020 Dec<br></br>
                          <b>GIS Developer </b>- Madinah Development Authority
                        </li>
                        <li>
                          2018 Sep - 2020 Mar<br></br>
                          <b>Technical Team Leader </b>- Arab Computer Apple Services
                        </li>
                        <li>
                          2018 Apr - 2018 Sep<br></br>
                          <b>Technical Support Specialist </b>- Oberoi Group
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          Team Leader
                        </li>
                        <li>
                          Public Speaking
                        </li>
                        <li>
                          Problem Solving
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                      <li>
                          Bachelor Degree Taibah University- Computer Science<br>
                          </br>
                          2012-2016
                        </li>
                        <li>
                          Project Management Professional PMP<br></br>
                          2022
                        </li>
                        <li>
                          Completion Course Of Cyper Secuirty<br></br>
                          2019
                        </li>
                        <li>
                          Completion Course Of CCNA<br></br>
                          2019
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a href="/service">
                            BSc In CSE
                            <span> - ABC University, Los Angeles, CA</span>
                          </a>{" "}
                          2010
                        </li>
                        <li>
                          <a href="/service">
                            Diploma in Computer Science
                            <span> - Gamma Technical Institute</span>
                          </a>{" "}
                          2009
                        </li>
                        <li>
                          <a href="/service">
                            Graphic Designer
                            <span> - ABC Institute, Los Angeles, CA</span>
                          </a>{" "}
                          2007
                        </li>
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
